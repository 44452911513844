// Colors https://www.figma.com/file/32W29dypC9tEkydDL2pDHs/PGB-Global-styles?node-id=11%3A34
$ds-color-black-trans: rgba(0, 0, 0, 0.5);
$ds-color-black: rgba(4, 37, 62, 1);
$ds-color-dark-grey: rgba(4, 37, 62, 0.8);
$ds-color-grey: rgba(4, 37, 62, 0.3);
$ds-color-grey-trans: rgba(4, 37, 62, 0.16);
$ds-color-medium-dark-grey: rgba(73, 80, 87, 1);
$ds-color-medium-darker-grey: rgba(151, 151, 151, 1);
$ds-color-medium-grey: rgba(166, 166, 166, 1);
$ds-color-medium-light-grey: rgba(206, 217, 224, 1);
$ds-color-light-grey: rgba(230, 233, 236, 1);
$ds-color-very-light-grey: rgba(242, 244, 245, 1);
$ds-color-white: rgba(255, 255, 255, 1);
$ds-color-white-medium-trans: rgba(255, 255, 255, 0.6);
$ds-color-white-trans: rgba(255, 255, 255, 0.8);

$ds-color-blue: rgba(20, 109, 199, 1);
$ds-color-blue-trans: rgba(64, 157, 255, 0.1);
$ds-color-blue-hover: rgba(57, 136, 227, 1);
$ds-color-blue-click: rgba(54, 133, 217, 1);
$ds-color-blue-checkbox: rgba(51, 123, 205, 1);
$ds-color-blue-button: rgba(51, 122, 183, 1);
$ds-color-medium-blue: rgba(54, 81, 101, 1);

/**
 Buttons
 */
$ds-color-blue-text-disabled: rgba(86, 147, 227, 1);
$ds-color-blue-disabled: rgba(196, 223, 245, 1);
$ds-color-gray-text-disabled: rgba(86, 147, 227, 1);
$ds-color-gray-bg-disabled: rgba(249, 251, 252, 1);

$ds-color-green: rgba(0, 128, 0, 1);

$ds-color-red: rgba(172, 41, 37, 1);
$ds-color-orange-red: rgba(255, 69, 0, 1);
$ds-color-orange: rgba(255, 133, 64, 1);
$ds-color-light-red: rgba(209, 101, 79, 1);
$ds-color-light-red-trans: rgba(209, 101, 79, 0.1);

$ds-color-cyan: rgba(45, 178, 150, 1);
$ds-color-cyan-medium-trans: rgba(45, 178, 150, 0.2);
$ds-color-cyan-trans: rgba(45, 178, 150, 0.1);

$ds-color-n300: rgba(117, 139, 156, 1);
$ds-color-n500: rgba(31, 37, 41, 1);

$validationIconPaddingRight: 30px;
$formControlHorizontalPadding: 12px;
$formControlVerticalPadding: 8px;
