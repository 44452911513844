/* You can add global styles to this file, and also import other style files */

@import '../../../libs/orderform-widgets/src/assets/global';

div.unzerSandboxNotify {
  display: none !important;
}

/**
 * Do not set margin for maybe empty widgets
 * because there is no content
 */
ds-orderform-currency-selector,
ds-orderform-input-additional,
ds-orderform-input-checkboxes,
ds-orderform-input-voucher,
ds-orderform-language-selector,
ds-orderform-payment-plans {
  &:empty {
    margin-top: 0 !important;
  }
}

/**
 * Disable interaction on Inputs of PGB is in editor mod
 */
body.scene {
  &.design-mode {
    // disable Payment-Methods only on design-mode
    ds-orderform-payment-methods {
      pointer-events: none;
    }
  }

  /**
   * disable global on any mode in editor
   */
  .DIE, // Disable elements (manual selection)
  ds-orderform-guarantee-seal,
  ds-orderform-currency-selector,
  ds-orderform-input-additional,
  ds-orderform-input-address,
  ds-orderform-input-checkboxes,
  ds-orderform-input-voucher,
  ds-orderform-language-selector,
  ds-orderform-legal-notice,
  ds-orderform-order-bump,
  ds-orderform-order-summary,
  ds-orderform-payment-plans,
  ds-orderform-product-list,
  ds-orderform-footer,
  ds-orderform-product-quantity-discount {
    input,
    select,
    textarea,
    button,
    a,
    label,
    p-dropdown {
      // Global disable for Angular - Inputs
      pointer-events: none;
    }
  }

  ds-orderform-guarantee-seal,
  ds-orderform-currency-selector,
  ds-orderform-input-additional,
  ds-orderform-input-address,
  ds-orderform-input-checkboxes,
  ds-orderform-input-voucher,
  ds-orderform-language-selector,
  ds-orderform-legal-notice,
  ds-orderform-order-summary,
  ds-orderform-payment-methods,
  ds-orderform-payment-plans,
  ds-orderform-product-list {
    &:empty {
      margin: 1em 0;
      padding: 0.5em;
      background-color: #409dff1a;
      display: block;
      &:before {
        content: 'Für das ausgewählte Vorschau-Produkt ist dieses Widget leer und wird nicht angezeigt.';
      }
    }
  }
}
